import { createRouter, createWebHashHistory } from 'vue-router'

// import IndexView from '../views/IndexView.vue'
// import NewsView from '../views/NewsView.vue'
// import NewsOverviewAllView from '../views/NewsOverviewAllView.vue'
// import HotIssueView from '../views/HotIssueView.vue'
// import PerformanceView from '../views/PerformanceView.vue'

// import HouseNotesView from '../views/HouseNotesView.vue'
// import HouseNotes_01_View from '../views/HouseNotes_01_View.vue'
// import HouseNotes_02_View from '../views/HouseNotes_02_View.vue'
// import HouseNotes_03_View from '../views/HouseNotes_03_View.vue'

// import LandServiceView from '../views/LandServiceView.vue'
// import ContactView from '../views/ContactView.vue'
// import Privacy from '../views/PrivacyView.vue'

const routes = [
  // 首頁-------------------------
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/IndexView.vue')
  },
  // 小城新聞----------------------
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue')
  },
  {
    path: '/news-overview-all',
    name: 'news-overview-all',
    component: () => import(/* webpackChunkName: "NewsOverviewAll" */ '../views/NewsOverviewAllView.vue')
  },
  {
    path: '/newspage/:id(\\d+)',
    name: 'newspage',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsPageView.vue')
  },

  // 熱線建案----------------------
  {
    path: '/hot-issue',
    name: 'hot-issue',
    component: () => import(/* webpackChunkName: "HotIssue" */ '../views/HotIssueView.vue')
  },
  {
    path: '/hotissuepage/:id(\\d+)',
    name: 'hotissuepage',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotIssuePageView.vue')
  },

  // 興城業績----------------------
  {
    path: '/performance',
    name: 'performance',
    component: () => import(/* webpackChunkName: "Performance" */ '../views/PerformanceView.vue')
    // component: () => import(/* webpackChunkName: "Performance" */ '../views/PerformanceView-test.vue')
  },
  // 買房筆記----------------------
  {
    path: '/house-notes',
    name: 'house-notes',
    component: () => import(/* webpackChunkName: "HouseNotes" */ '../views/HouseNotesView.vue'),
    // children: [
    //   {
    //     path: 'houseNotes_01',
    //     name: 'houseNotes_01',
    //     component: () => import(/* webpackChunkName: "HouseNotes_01" */ '../views/HouseNotes_01_View.vue')
    //   },
    //   {
    //     path: 'houseNotes_02',
    //     name: 'houseNotes_02',
    //     component: () => import(/* webpackChunkName: "HouseNotes_02" */ '../views/HouseNotes_02_View.vue')
    //   },
    //   {
    //     path: 'houseNotes_03',
    //     name: 'houseNotes_03',
    //     component: () => import(/* webpackChunkName: "HouseNotes_03" */ '../views/HouseNotes_03_View.vue')
    //   },
    // ]
  },
  {
    path: '/house-notes/houseNotes_01',
    name: 'houseNotes_01',
    component: () => import(/* webpackChunkName: "HouseNotes_01" */ '../views/HouseNotes_01_View.vue')
  },
  {
    path: '/house-notes/houseNotes_02',
    name: 'houseNotes_02',
    component: () => import(/* webpackChunkName: "HouseNotes_02" */ '../views/HouseNotes_02_View.vue')
  },
  {
    path: '/house-notes/houseNotes_03',
    name: 'houseNotes_03',
    component: () => import(/* webpackChunkName: "HouseNotes_03" */ '../views/HouseNotes_03_View.vue')
  },
  // 土地評估----------------------
  {
    path: '/land-service',
    name: 'land-service',
    component: () => import(/* webpackChunkName: "LandService" */ '../views/LandServiceView.vue')
  },
  // 客戶售服----------------------
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/ContactView.vue')
  },
  // 邀約合作----------------------
  {
    path: '/invite',
    name: 'invite',
    component: () => import(/* webpackChunkName: "Invite" */ '../views/InviteView.vue')
  },
  // 隱私權政策--------------------
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/PrivacyView.vue')
  },
  // 寄信E-MAIL--------------------
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue')
  },
  // 404--------------------
  // {
  //   path: '/404',
  //   name: 'Error',
  //   component: () => import(/* webpackChunkName: "Error" */ '../views/ErrorView.vue')
  // },
  // 只要有出現異常網址(導回首頁)--------
  {
    path: '/:pathMatch(.*)*',
    redirect:{
      name: 'index',
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    // 如果有保存的滾動位置（如返回上一頁），則滾動到保存的位置
    if (savedPosition) {
      return savedPosition;
    } else {
      // 否則滾動到頂部
      return { top: 0 };
    }
  },
})


export default router
